<template>
  <div class='sign-in-wrapper'>
    <div class="sales-cta">
      <a href="https://orbitalstack.com/" target="blank">
        <img class="sign-in-logo" src="~@/assets/svg/sales-card.svg" alt="Schedule a Demo">
      </a>
      <div class="cta">
        <p class="big">Rapid insights to guide your design decisions now, saving you from costly mistakes later.</p>
        <div class="button-row">
          <a class="button" href="https://meetings.hubspot.com/chrissy-lemmex/demo-meeting-link?__hstc=57679477.15ff98b6cf027bfc24c411091436bad3.1707572445976.1742327462682.1742331295376.629&__hssc=57679477.4.1742331295376&__hsfp=1838488382&uuid=221c1b6a-10d4-410b-988c-9c6f357f16b7" target="_blank">Schedule a Demo</a>
        </div>
      </div>
    </div>
    <div class='form-wrapper'>
      <div class='sign-in-form'>
        <h4 class="page-header">Sign up</h4>
        <div>
            <b-alert show variant="primary">
              <h4 class="h4-smaller">Does your company already use Orbital Stack?</h4>
              <p>
              Skip this form and speak
              to your company administrator to gain access to shared projects and credits.
              </p>
              <h4 class="h4-smaller">Are you the company trailblazer?</h4>
              <p>Fill out the form and start your free trial today!</p>
            </b-alert>
        </div>
        <notification-banner
          v-if='this.error'
          theme='error'
          title='Error!'
          :message='this.error'
          :show-close-button='false' />
        <br>
        <FormulateForm class='username closer' @submit='signup()'>
          <div class='d-flex flex-row'>
            <FormulateInput
              label='First name'
              class='mx-2 w-50 mb-2'
              element-class='mt-0'
              type='text'
              id='firstName'
              name='firstName'
              validation='required'
              v-model='firstName' />
            <FormulateInput
              label='Last name'
              class='mt-0 mx-2 w-50 mb-2'
              element-class='mt-0'
              type='text'
              id='lastName'
              name='lastName'
              validation='required'
              v-model='lastName' />
          </div>
          <div class='d-flex flex-row mt-0'>
            <FormulateInput
              label='Email address (username)'
              class='mx-2 w-50 mb-2'
              element-class='mt-0'
              type='email'
              id='email'
              name='email'
              validation='bail|required|email'
              v-model='email' />
            <FormulateInput
              label='Company name'
              class='mt-0 mx-2 w-50 mb-2'
              element-class='mt-0'
              type='text'
              id='companyName'
              name='companyName'
              validation='required'
              v-model='companyName' />
          </div>
          <div class='d-flex flex-row mt-0'>
            <FormulateInput
              label='Country'
              :class='country === "Canada" ? "mx-2 w-50 mb-2" : "mx-2 w-100 mb-2"'
              element-class='mt-0'
              :options='countryOptions'
              type='select'
              name='country'
              validation='required'
              v-model='country'/>
            <FormulateInput
              v-if='country === "Canada"'
              label='Province'
              class='mt-0 mx-2 w-50 mb-2'
              element-class='mt-0'
              :options='provinceOptions'
              type='select'
              name='province'
              validation='required'
              v-model='province'/>
          </div>
          <div class='d-flex flex-row mt-0'>
            <FormulateInput
              label='Password'
              class='mx-2 w-50 mb-2'
              element-class='mt-0'
              type='password'
              id='password'
              name='password'
              validation='bail|required|min:8'
              v-model='password' />
            <FormulateInput
              label='Confirm password'
              class='mt-0 mx-2 w-50 mb-2'
              element-class='mt-0'
              type='password'
              id='confirmPassword'
              name='confirmPassword'
              validation='bail|required|matchPassword'
              :validation-rules='{ matchPassword: ({value}) => value === this.password }'
              :validation-messages='{ matchPassword: "Must match password."}'
              v-model='confirmPassword' />
          </div>
          <div class='d-flex flex-row mt-0'>
            <FormulateInput
              label='Promo code'
              class='mt-0 mx-2 w-100 mb-2'
              element-class='mt-0'
              type='text'
              id='promoCode'
              name='promoCode'
              v-model='promoCode'/>
          </div>
          <p style='font-size: 0.813rem;' class='mt-3'>
            By clicking "Sign up" and registering for an account,
            you are providing your consent for us to process the personal information you are submitting in accordance with our
            <a href='https://help.orbitalstack.com/privacy/' target="_blank">privacy policy.</a>
          </p>
          <div class='button-row'>
            <button class='signup-button'>
              <b-spinner v-if='creatingUser' small></b-spinner>
              Sign up
            </button>
          </div>
        </FormulateForm>
      </div>
      <div class='text-center'>
        Already have an account?
        <router-link :to="{ name: 'LoginPage'}" class="ghost-button">Sign in</router-link>
      </div>
    </div>
    <div class="sign-in-footer">
      <p class="smaller">©{{ `${new Date().getFullYear()}` }} <a href="https://www.orbitalstack.com/">Orbital Stack</a>. All rights reserved. Unauthorized duplication is strictly prohibited.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  AUTH_SIGNUP,
  GET_COUNTRIES,
  GET_PROVINCES
} from '@/store/actions/auth';
import { SIGNUP_SUCCESS } from '@/store/statuses/auth';
import { NotificationBanner } from 'rwdi-widgets';

export default {
  name: 'SignupPage',
  components: {
    NotificationBanner
  },
  mounted() {
    // update data when mounting the component
    if ('email' in this.$route.query) {
      this.email = this.$route.query.email;
    }

    if ('source' in this.$route.query) {
      this.source = this.$route.query.source;
    }
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      country: null,
      province: null,
      password: '',
      confirmPassword: '',
      promoCode: '',
      marketingOptIn: false,
      error: '',
      creatingUser: false,
      source: 'Organic'
    };
  },
  computed: {
    userData() {
      return {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        company_name: this.companyName,
        password: this.password,
        country: this.country,
        province: this.province,
        promo_code: this.promoCode,
        marketing_opt_in: false,
        source: this.source,
        marketing_opt_in_offered: false
      };
    },
    countryOptions() {
      return this.countries.map(country => country.name);
    },
    provinceOptions() {
      return this.provinces.map(province => province.name);
    },
    ...mapGetters(['authState', 'countries', 'provinces'])
  },
  async created() {
    await this.$store.dispatch(GET_COUNTRIES);
    await this.$store.dispatch(GET_PROVINCES);
  },
  methods: {
    async signup() {
      this.creatingUser = true;
      await this.$store.dispatch(AUTH_SIGNUP, this.userData);
      if (this.authState.status === SIGNUP_SUCCESS) {
        this.$router.push({ name: 'LoginPage', query: { from: this.$route.query.from } });
      } else {
        this.creatingUser = false;
        this.error = Object.values(this.$store.getters['authState'].signup_error)[0][0];
      }
    }
  }
};
</script>

<style scoped>
*, * > * {
  box-sizing: border-box!important;
}

body, html {
  height: 100vh;
}

.ghost-button {
  font-size: 0.75em;
  text-align: right;
  font-weight: 700;
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: var(--primary-navy);
  align-items: stretch;
}

.opt-in-text {
  font-size: 0.625rem;
  font-weight: normal;
}

.sales-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  flex-grow: 1;
  background-color: var(--primary-navy);
}

.sales-cta {
  color: #fff;
}

.cta {
  display: none;
}

.sign-in-footer {
  color: #fff;
  padding: 1em;
  text-align: center;
}

.sign-in-footer a {
  color: #fff;
}

a.button {
  text-decoration: none;
}

.sales-cta a {
  color: #fff;
  margin: 0 auto;
}

.sign-in-logo {
  width: 100%;
  height: auto;
  max-width: 15.625rem;
  padding: 1em
}

.form-wrapper {
  flex-grow: 1;
}

.sign-in-form {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0.188rem rgba(0, 0, 0, 0.1);
  max-width: 37.5rem;
  margin: 0 auto;
}

.sign-in-form .button-row {
  justify-content: space-between;
}

.sign-in-form a {
  align-self: center;
}

.page-header {
  font-size: 1.25em;;
}

.h4-smaller {
  font-size: 1em;
}

.signup-button {
  width: 100%;
  margin: 0;
}

@media screen and (min-width: 52rem) {

  .sign-in-wrapper {
    flex-direction: row;
    background-color: var(--grey-200);
    align-items: stretch;
  }

  .cta {
    display: block;
    margin: auto 0;
  }

  .sales-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    max-width: 25rem;
    height: 100%;
    text-align: center;
    padding: 1.5rem;
    background-color: var(--primary-navy);
  }

  .sign-in-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 25rem;
  }
}
</style>
<style>
.formulate-input[data-classification=box] .formulate-input-element input:focus~.formulate-input-element-decorator {
  border: 0.063rem solid #cecece;
}

.formulate-input[data-classification=box] .formulate-input-element input[type=checkbox]:checked~.formulate-input-element-decorator:before {
  background-color: #007bff;
}

.formulate-input[data-classification=select] select:focus {
  border: 0.063rem solid #cecece;
}
</style>